.dropdown {
    position: relative;
    display: inline-block;
    width: 95px;
    height: 40px;
  }
  
  .dropdown-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #e0d68a;
    padding: 5px;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .dropdown-header img {
    width: 32px;
    height: 32px;
    margin-right: 5px;
  }
  
  .dropdown-header .arrow {
    margin-left: auto;
    color: black;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #1a1a1a;
    border-radius: 10px;
    margin-top: 5px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .dropdown-item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
  }
  
  .dropdown-item img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  
  .dropdown-item:hover {
    background-color: #333;
  }