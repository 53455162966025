:root {
  --speed-low: 2.6s;
  --speed-mid: 2.6s;
  --speed-fast: 2.6s;
}

.split {
  position: relative;
  display: inline-block;
  overflow: hidden;
  /* margin: 15px; */
  color: transparent;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    transform-origin: 0 0;
    transform: translateX(calc(-100% - 1px));
  }

  animation: var(--speed) text;

  &::before {
    animation: var(--speed) mask;
  }
}

.animate-fast {
  animation: var(--speed-fast) text;
  &::before {
    animation: var(--speed-fast) mask;
  }
}

.animate-mid {
  animation: var(--speed-fast) text;
  &::before {
    animation: var(--speed-fast) mask;
  }
}

.animate-low {
  animation: var(--speed-low) text;
  animation-fill-mode: both;
  &::before {
    animation: var(--speed-low) mask;
    animation-fill-mode: both;
  }
}

@keyframes mask {
  0% {
    transform: translateX(calc(-100% - 1px));
    opacity: 1;
  }

  5% {
    transform: translateX(0);
    opacity: 1;
  }

  10% {
    transform: translateX(0);
    opacity: 1;
  }

  15% {
    transform: translateX(calc(100% + 1px));
    opacity: 1;
  }

  16% {
    transform: translateX(calc(100% + 1px));
    opacity: 0;
  }

  17% {
    transform: translateX(calc(-100% - 1px));
    opacity: 0;
  }
  85% {
    transform: translateX(calc(-100% - 1px));
    opacity: 1;
  }

  90% {
    transform: translateX(0);
    opacity: 1;
  }

  95% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(calc(100% + 1px));
    opacity: 1;
  }
}

@keyframes text {
  0% {
    color: transparent;
    /* opacity: 0; */
  }

  5% {
    color: transparent;
    /* opacity: 1; */
  }

  6% {
    color: inherit;
    /* opacity: 1; */
  }

  94% {
    color: inherit;
    /* opacity: 1; */
  }

  95% {
    color: transparent;
    /* opacity: 1; */
  }

  100% {
    color: transparent;
    /* opacity: 0; */
  }
}
@keyframes mask-showOnly {
  0% {
    transform: translateX(calc(-100% - 1px));
    opacity: 1;
  }

  5% {
    transform: translateX(0);
    opacity: 1;
  }

  10% {
    transform: translateX(0);
    opacity: 1;
  }

  15% {
    transform: translateX(calc(100% + 1px));
    opacity: 1;
  }

  16% {
    transform: translateX(calc(100% + 1px));
    opacity: 0;
  }
  
  100% {
    transform: translateX(calc(100% + 1px));
    opacity: 0;
  }
}

@keyframes text-showOnly {
  0% {
    color: transparent;
    /* opacity: 0; */
  }

  5% {
    color: transparent;
    /* opacity: 1; */
  }

  6% {
    color: inherit;
    /* opacity: 1; */
  }

  94% {
    color: inherit;
    /* opacity: 1; */
  }

  95% {
    color: inherit;
    /* opacity: 1; */
  }

  100% {
    color: inherit;
    /* opacity: 0; */
  }
}

.connect-button {
  opacity: 0;
  animation: var(--speed-low) fade-in;
  animation-fill-mode: both;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}


.otp-input{
  width: 46px !important;
  height: 46px;
  background: transparent;
  border: 1px solid #FFFFFF66;
  border-radius: 12px;
  text-transform: uppercase;
}
.otp-input:focus-visible{
  border: 1px solid white;
  outline: none;
}

.img-fade {
  animation: 3.5s img-fade-in infinite;
}

@keyframes img-fade-in {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  40% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.img-fade-mobile {
  animation: 3.5s img-fade-mobile-in infinite;
}

@keyframes img-fade-mobile-in {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0.1;
  }

  40% {
    opacity: 0.1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}
